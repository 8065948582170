<template>
  <div>
    <b-card title="Add a new barcode">
      <validation-observer
        ref="addBarcodeValidation"
        mode="lazy"
      >
        <b-form
          class="w-100"
          @submit.prevent="addBarcode"
        >
          <b-row>
            <!-- DU Barcode -->
            <b-col
              cols="6"
            >
              <b-form-group
                label="DU Barcode"
                label-for="v-du-barcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="DU Barcode"
                  :rules="validateDuBarcode"
                  mode="lazy"
                >
                  <b-form-input
                    id="v-du-barcode"
                    v-model="formData.duBarcode"
                    placeholder="Enter the DU Barcode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- COR Barcode -->
            <!-- <b-col
              cols="6"
            >
              <b-form-group
                label="COR Barcode"
                label-for="v-cor-barcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="COR Barcode"
                  :rules="{ regex: /^NH-[0-9]{5}-COR$/, required: true }"
                  mode="lazy"
                >
                  <b-form-input
                    id="v-cor-barcode"
                    v-model="formData.corBarcode"
                    placeholder="Enter the COR Barcode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <!-- ST Barcode -->
            <b-col
              cols="6"
            >
              <b-form-group
                label="ST Barcode"
                label-for="v-st-barcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ST Barcode"
                  :rules="validateStBarcode"
                  mode="lazy"
                >
                  <b-form-input
                    id="v-st-barcode"
                    v-model="formData.stBarcode"
                    placeholder="Enter the ST Barcode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- SA Barcode -->
            <b-col
              cols="6"
            >
              <b-form-group
                label="SA Barcode"
                label-for="sa-barcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="SA Barcode"
                  :rules="validateSaBarcode"
                  mode="lazy"
                >
                  <b-form-input
                    id="sa-barcode"
                    v-model="formData.saBarcode"
                    placeholder="Enter the SA Barcode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group
                label="Barcode Tag"
                label-for="v-tag"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Barcode Tag"
                  rules=""
                  mode="lazy"
                >
                  <v-select
                    v-model="formData.tags"
                    :options="tags"
                    label="name"
                    multiple
                  >
                    <template v-slot:option="option">
                      <barcode-tag
                        :color="option.color"
                        :tag-name="option.name"
                        :tag-desc="option.tooltip"
                      />
                    </template>
                    <template #list-footer>
                      <div
                        style="opacity: 0.8; padding: 0.3rem; text-align: center; cursor: pointer;"
                        @click="modal = !modal"
                      >
                        Add new tag
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit -->
            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
              >
                <span v-if="loading !== true">Submit</span>
                <b-spinner
                  v-else
                  variant="white"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-modal
        v-model="modal"
        title="Add new tag"
        ok-title="Add"
        ok-only
        @ok="addTag"
      >
        <validation-observer
          ref="addTagValidation"
          mode="lazy"
        >
          <b-form
            class="w-100"
            @submit.prevent="addBarcode"
          >
            <b-row>
              <b-col cols="6">
                <!-- Input tag for tag name -->
                <b-form-group
                  label="Tag Name"
                  label-for="v-tag"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tag Name"
                    rules="required"
                    mode="lazy"
                  >
                    <b-form-input
                      id="v-tag"
                      v-model="tagName"
                      placeholder="Enter tag name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <!-- Input form tag description -->
                <b-form-group
                  label="Tag Description"
                  label-for="v-description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tag Description"
                    :rules="{ regex: /^.{0,20}$/ }"
                    mode="lazy"
                  >
                    <b-form-input
                      id="v-description"
                      v-model="tagDesc"
                      placeholder="Enter tag description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <barcode-tag
                  :color="color"
                  :tag-name="tagName"
                  :tag-desc="tagDesc"
                />
                <b-button
                  v-if="!colorShow"
                  variant="primary"
                  size="sm"
                  class="float-right"
                  @click="colorShow = !colorShow"
                >Color</b-button>
                <color-panel
                  v-if="colorShow"
                  v-model="color"
                  @change="colorShow = !colorShow"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
    <b-card title="Add Multiple Barcodes">
      <input
        id="excelFile"
        type="file"
        @input="onFileChange"
      >
      <div class="mt-2">
        <b-button
          variant="primary"
          :disabled="barcodesFile === null"
          type="submit"
          @click="createMultipleBarcodes"
        >
          <span v-if="loading !== true">Submit</span>
          <b-spinner
            v-else
            variant="white"
          />
        </b-button>
      </div>
    </b-card>
    <b-card title="Re-Map barcode">
      <validation-observer
        ref="remapBarcodeValidation"
        mode="lazy"
      >
        <b-row
          v-for="(pair, index) in barcodePairs"
          :key="index"
        >
          <!-- DU Barcode -->
          <b-col
            cols="5"
          >
            <b-form-group
              label="Old Barcode"
              label-for="old-barcode"
            >
              <validation-provider
                #default="{ errors }"
                name="Old Barcode"
                rules="required"
                mode="lazy"
              >
                <b-form-input
                  id="old-barcode"
                  v-model="pair.barcodeX"
                  placeholder="Enter the Old Barcode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="5"
          >
            <b-form-group
              label="New Barcode"
              label-for="new-barcode"
            >
              <validation-provider
                #default="{ errors }"
                name="New Barcode"
                rules="required"
                mode="lazy"
              >
                <b-form-input
                  id="new-barcode"
                  v-model="pair.barcodeY"
                  placeholder="Enter the New Barcode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            class="align-content-around"
            style="margin-top: 0.5rem;"
          >
            <b-button
              v-if="barcodePairs.length > 1"
              variant="danger"
              @click="removePair(index)"
            >
              <feather-icon
                size="16"
                icon="XIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <b-button
        variant="light"
        class="mt-2 mr-2"
        @click="addPair"
      >
        <span>Add</span>
      </b-button>
      <b-button
        variant="primary"
        type="submit"
        class="mt-2"
        @click="submitMapping"
      >
        <span v-if="loading !== true">Submit</span>
        <b-spinner
          v-else
          variant="white"
        />
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BarcodeTag from '@/components/tag.vue'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import { read, utils } from 'xlsx'
/* eslint-disable no-param-reassign */
import Ripple from 'vue-ripple-directive'
/* eslint-disable no-return-assign */
export default {
  directives: { Ripple },
  components: {
    ValidationObserver,
    ValidationProvider,
    BarcodeTag,
    vSelect,
  },
  data() {
    return {
      // component variables
      loading: false,
      barcodesFile: null,
      barcodesJson: {},
      required,
      email,
      barcodePairs: [
        { barcodeX: '', barcodeY: '' }, // Initial pair
      ],
      // form data
      formData: {
        duBarcode: '',
        // corBarcode: '',
        stBarcode: '',
        saBarcode: '',
        tags: [],
      },
      tags: [],
      modal: false,
      colorShow: false,
      tagName: '',
      tagDesc: '',
      color: '#B4B9BF',
    }
  },
  computed: {
    validateDuBarcode() {
      return (this.formData.saBarcode || this.formData.stBarcode)
        ? { regex: /^NH-[0-9]{7}-DU$/ }
        : { regex: /^NH-[0-9]{7}-DU$/, required: true }
    },
    validateSaBarcode() {
      return this.formData.duBarcode !== ''
        ? { regex: /^NH-[0-9]{7}-SA$/, required: true }
        : { regex: /^NH-[0-9]{7}-SA$/ }
    },
    validateStBarcode() {
      if (this.formData.duBarcode !== '') {
        return { regex: /^NH-[0-9]{7}-ST$/, required: true }
      }
      return { regex: /^NH-[0-9]{7}-ST$/ }
    },
    mappedBarcodes() {
      const mapping = {}
      this.barcodePairs.forEach(pair => {
        mapping[pair.barcodeX] = pair.barcodeY
      })
      return mapping
    },
  },
  beforeMount() {
    this.$http.get('/tags', { params: { queryParams: {} } }).then(({ data }) => {
      this.tags = data.list
    })
  },
  methods: {
    addTag() {
      // addTagValidation
      this.$refs.addTagValidation.validate().then(result => {
        if (result) {
          this.$http.post('/tags', {
            name: this.tagName,
            color: this.color,
            tooltip: this.tagDesc,
          }).then(() => {
            this.tags.push({
              name: this.tagName,
              color: this.color,
              tooltip: this.tagDesc,
            })
            this.formData.tags.push(this.tags[this.tags.length - 1])
            // this.modal = !this.modal
            this.tagName = ''
            this.tagDesc = ''
            this.color = ''
          })
        } else {
          //
        }
      })
    },
    async onFileChange(event) {
      this.barcodesFile = event.target.files ? event.target.files[0] : null
      const barcodesFileArrayBuffer = await this.barcodesFile.arrayBuffer()
      const barcodeFileData = read(barcodesFileArrayBuffer)
      this.barcodesJson = utils.sheet_to_json(barcodeFileData.Sheets[barcodeFileData.SheetNames[0]])
    },
    addPair() {
      this.barcodePairs.push({ barcodeX: '', barcodeY: '' })
    },
    removePair(index) {
      if (this.barcodePairs.length > 1) {
        this.barcodePairs.splice(index, 1)
      }
    },
    submitMapping() {
      // Here you can implement the logic to send the mapping to a request
      // For demonstration purpose, let's just log it to the console
      // console.log(this.mappedBarcodes)
      this.$refs.remapBarcodeValidation.validate().then(success => {
        if (success) {
          this.$http
            .post('/barcode/remap', this.mappedBarcodes)
            .then(() => {
              this.$bvToast.toast('Barcode Remapping Successful!', {
                title: 'All Barcodes remapped',
                variant: 'primary',
                solid: true,
              })
            })
            .catch(this.handleError)
        }
      })
    },
    createMultipleBarcodes() {
      this.$http.post('/barcode/create_bulk', this.barcodesJson).then(() => {
        this.$bvToast.toast('Barcodes created successfully!', {
          title: 'All Barcodes created',
          variant: 'primary',
          solid: true,
        })
      }).catch(err => {
        document.getElementById('excelFile').value = null
        this.handleError(err)
      })
    },
    addBarcode() {
      this.$refs.addBarcodeValidation.validate().then(success => {
        if (success) {
          const data = JSON.parse(JSON.stringify(this.formData))
          this.$http
            .post('/barcodes', data)
            .then(() => {
              this.$router.push({ name: 'list-barcodes' })
            })
            .catch(this.handleError)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr 1fr;
}
</style>
<style lang="scss">
.modal .modal-content {
  height: 90vh;
}
</style>
